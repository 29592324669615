/* This source code is exported from pxCode, you can get more document from https://www.pxcode.io */
.block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: #fafafa;
  width: 100%;
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media (max-width: 991px) {
  .flex {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    row-gap: 0px;
  }
}

.flex_layout {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  /* overflow: hidden; */
}

.flex_item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  height: 100vh;
  overflow: hidden;
}

@media (max-width: 767px) {
  .flex_item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    height: fit-content;
  }
  .flex_layout {
    position: relative;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
  }
}

.layout {
  width: 100%;
  height: 100%;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.flex_spacer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
}

@media (max-width: 991px) {
  .flex_spacer {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 0px;
            flex: 0 0 0px;
  }
}

@media (max-width: 767px) {
  .flex_spacer {
    display: none;
  }
}

.flex_item1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 734px;
          flex: 1 1 734px;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

@media (max-width: 991px) {
  .flex_item1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
  }
}

@media (max-width: 767px) {
  .flex_item1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    height: fit-content;
  }
}

.container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.container_layout {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: #fafafa;
}

.main_layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  width: 80.31%;
  margin: 60px auto 0px;
}

@media (max-width: 767px) {
  .main_layout {
    margin: 40px auto 0px;
  }
}

.main_item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  -webkit-box-flex: 0;
      -ms-flex: 0 1 16px;
          flex: 0 1 16px;
}

.checkInTitle {
  font: 700 16px/1 "Noto Sans TC", Helvetica, Arial, serif;
  color: #4a4a4a;
  letter-spacing: 0.6px;
}

.checkInTitle_layout {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  min-width: 0px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 40px 0px 24px;
}

.resultsTitle {
  font: 700 16px/1 "Noto Sans TC", Helvetica, Arial, serif;
  color: #4a4a4a;
  letter-spacing: 0.6px;
}

.noAwardsTitle {
  text-align: center;
  margin-top: 10px;
}

.resultsTitle_layout {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  min-width: 0px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 40px 0px 24px;
}

.main_spacer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
}
/*# sourceMappingURL=ProfilePage.module.css.map */


.tabs {
  font: 700 16px/1 "Noto Sans TC", Helvetica, Arial, serif;
  color: #4A4A4A;
  display: flex;
  justify-content: center;
  margin: 40px 0px 24px;
}

.tabsItem {
  margin: 0 40px;
}
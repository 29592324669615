.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  row-gap: 10px;
  max-width: 400px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  font-weight: 700;
  font-family: "Noto Sans TC", Helvetica, Arial, serif;
}

.closeIcon {
  font-size: 26px;
  align-self: flex-end;
}

.redeem_container {
  width: 100%;
  padding: 10px 0 60px;
}

.btn_primary {
  width: 90%;
  margin: 0 auto;
  color: #FFF;
  background-color: #4A4A4A;
  text-align: center;
  border-radius: 8px;
  font-size: 14px;
  padding: 16px;
  margin-top: 16px;
  cursor: pointer;
  font-weight: 700;
}
.btm_secondary {
  width: 90%;
  color: #4A4A4A;
  background-color: #FFF;
  text-align: center;
  border-radius: 8px;
  font-size: 14px;
  padding: 16px;
  margin-top: 16px;
  cursor: pointer;
  border: 1px solid #4A4A4A;
  font-weight: 700;
}

.state_container {
  display: flex;
  justify-content: center;
  column-gap: 16px;
  width: 100%;
  margin-bottom: 20px;
}

.state {
  background-color: #FAFAFA;
  box-shadow: 0px 20px 34px rgba(0, 0, 0, 0.08);
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;

}
.state_title {
  font-size: 18px;
}
.state_number {
  font-size: 40px;
}
.state_points {
  font-size: 12px;
}




.mission_container_wrapper {
  flex: 1;
  width: 100%;
  height: 100%;
}
.mission_container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 16px;
  column-gap: 8px;
}

.mission {
  background-color: #E0E0E0;
  color: #FFF;
  height: 100%;
  width: 100%;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  justify-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.mission_title {
  font-size: 14px;
  margin-top: 10px;
}
.mission_number {
  font-size: 40px;
  margin-top: -12px;
}

.musk {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
}

.pop {
  background-color: #FFF;
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  height: 55%;
  max-height: 420px;
  border-radius: 16px 16px 0 0;
  transform: translateY(100%);
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transition: .4s ease-in-out;
}
.pop_main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pop_close_btn {
  text-align: end;
  padding: 10px 16px;
  font-size: 16px;
}
.pop_title {
  margin-bottom: 24px;
  font-size: 20px;
  font-weight: 700;
}

.modal_redeem_opt_container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 20px 0;
  font-size: 16px;
}
.modal_redeem_opt {
  font-weight: 400;
  font-size: 20px;
  font-family: "Noto Sans TC", Helvetica, Arial, serif;
}
.modal_confirm_txt {
  font-size: 20px;
  padding: 20px 0;
  font-family: "Noto Sans TC", Helvetica, Arial, serif;
}
.modal_confirm_row {
  display: flex;
  column-gap: 16px;
}
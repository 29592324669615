.page {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  padding: 20px;
  height: 77px;
}

.body {
  flex: 1;
  display: grid;
  align-content: center;
  background-size: cover;
  background-position: center;
}

.name {
  width: calc(100% - 66px);
  height: 100%;
  display: inline-block;
  margin-left: 20px;
  font-weight: 700;
  font-size: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content {
  background-color: #FFFFFFEE;
  width: 50%;
  margin: auto auto;
  margin-top: 30px;
  min-width: 320px;
  max-width: 500px;
  padding: 15px;
  border-radius: 15px;
}

.logo {
  margin-bottom: 10px;
  text-align: center;
}

.logoSmall {
  height: 100%;
  vertical-align: inherit;
}
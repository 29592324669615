body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-tabs-content-holder > .ant-tabs-content {
  height: 100%;
}

#root {
  height: 100%;
}

.ant-form-item-explain-error {
  text-align: right;
  margin-top: 2px;
}

#profile-page-tabs .ant-tabs-nav::before {
  border-bottom: none;
}

#profile-page-tabs .ant-tabs-nav::before {
  border-bottom: none;
}

#profile-page-tabs .ant-tabs-tab-btn {
  padding: 10px 30px;
  font-size: 16px;
  margin-top: 40px;
}

#profile-page-tabs .ant-tabs-ink-bar-animated {
  height: 0;
}

#profile-page-tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
  border-radius: 6px;
  background-color: #4A4A4A;
  color: #FFF;
}

.ant-modal-content {
  border-radius: 16px !important;
}

.ant-radio-wrapper {
  font-size: inherit !important;
}

@media (max-width: 479px) {
  .ant-form-item-explain {
    font-size: 12px !important;
  }
}

.ant-drawer-bottom .ant-drawer-content {
  border-radius: 16px 16px 0px 0px;
}

.boothNumberInput .ant-input {
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 1px;
}

.confirm-transfer-modal .ant-modal-content {
  border-radius: 16px;
}
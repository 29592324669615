:root {
    --msg__padding: 7px 10px !important;
    --msg__author__fontSize: 12px !important;
    --msg__avatar__margin: 2px 10px 0 0 !important;
    --msg__bubble__padding: 6px 10px !important;;
    --msg__main__maxWidth: none;
    --msg-input__background: #f0f3f7 !important;
    --msg-input__padding: 10px 10px !important;
    --msg-input__send__background: #676767 !important;
}

.pn-msg__bubble {
  word-wrap: break-word;
  word-break: break-all;
}

.pn-msg-list__spacer {
  flex: none;
}

/* This source code is exported from pxCode, you can get more document from https://www.pxcode.io */
.resultCard {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: rgba(255, 255, 255, 0.8);
    color: #4a4a4a;
  -webkit-box-shadow: 0px 20px 34px 0px rgba(0, 0, 0, 0.0784314);
          box-shadow: 0px 20px 34px 0px rgba(0, 0, 0, 0.0784314);
  border-radius: 16px 16px 16px 16px;
}

.resultCard_layout {
  position: relative;
}

.resultCardRed {
  background-color: rgba(255, 212, 201, 1);
    color: rgba(112, 50, 50, 1);
}

.block16 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.block16_layout {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  margin: 20px;
}

.subtitle {
  font: 700 14px/1.6 "Noto Sans TC", Helvetica, Arial, serif;
  letter-spacing: 1px;
  opacity: 0.69;
}

.subtitle_layout {
  position: relative;
}

.title {
  font: 700 18px/1.6 "Noto Sans TC", Helvetica, Arial, serif;
  letter-spacing: 1px;
}

.title_layout {
  position: relative;
  margin: 5px 0px;
}
/*# sourceMappingURL=ResultCard.module.css.map */

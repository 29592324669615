/* This source code is exported from pxCode, you can get more document from https://www.pxcode.io */
.sessionCards_layout {
  position: relative;
}

@media (max-width: 991px) {
  .sessionCards_layout {
    margin: 0px;
  }
}

.sessionCards {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 24px;
}

.card {
  cursor: pointer;
}

.card_layout {
  position: relative;
  height: 104px;
  width: 100%;
  margin: 0px 0px;
}

.sessionCard {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-box-shadow: 0px 20px 34px 0px rgba(0, 0, 0, 0.0784314);
          box-shadow: 0px 20px 34px 0px rgba(0, 0, 0, 0.0784314);
  border-radius: 16px 16px 16px 16px;
  cursor: pointer;
}

.sessionCard_layout {
  position: relative;
  height: 104px;
  width: 100%;
  margin: 0px 0px;
}

.sessionCard_item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 546px;
          flex: 1 1 546px;
}

.card1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card1_layout {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  margin: 20px 0px 20px 20px;
}

.subtitle {
  font: 700 14px/1.6 "Noto Sans TC", Helvetica, Arial, serif;
  color: #4a4a4a;
  letter-spacing: 1px;
  opacity: 0.69;
}

.subtitle_layout {
  position: relative;
}

.card_item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
}

.title {
  font: 700 18px/1.6 "Noto Sans TC", Helvetica, Arial, serif;
  color: #4a4a4a;
  letter-spacing: 1px;
}

@media (max-width: 479px) {
  .title {
    text-align: justify;
  }
}

.title_layout {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  min-width: 0px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 7px 0px 5px;
}

.sessionCard_spacer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  -webkit-box-flex: 0;
      -ms-flex: 0 1 10px;
          flex: 0 1 10px;
}

.sessionCard_item1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
}

.icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  height: 100%;
}

.icon1 {
  width: 100%;
  height: auto;
  vertical-align: top;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center top;
     object-position: center top;
}

.icon1_layout {
  position: relative;
  height: 13px;
  width: 19px;
  min-width: 19px;
  margin: 11px 9px 14px 10px;
}
/*# sourceMappingURL=ProfilePage_sessions.module.css.map */
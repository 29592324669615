.title {
  font-size: 2em;
  margin-bottom: .5em;
  text-align: center;
}

.subtitle {
  color: #7F7F7F;
  font-size: 1em;
  margin-bottom: 1.5em;
  text-align: center;
}

.block_btn {
  height: 40px;
  margin-bottom: 20px;
}
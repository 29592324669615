/* This source code is exported from pxCode, you can get more document from https://www.pxcode.io */
.topCover {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  overflow: hidden;
  height: 100vh;
  position: relative;
}

.backgroungImg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: auto;
}

@media (max-width: 1400px) {
  .topCover {
    margin: 0px;
  }

  .backgroungImg {
    width: auto;
    min-height: 100vh;
    max-height: 120vh;
  }
}

@media (max-width: 767px) {
  .topCover {
    height: fit-content;
  }

  .backgroungImg {
    width: 100%;
    height: auto;
    min-height: auto;
    max-height: none;
  }
}

@media (max-width: 479px) {
  .backgroungImg {
    min-width: 385px;
  }
}

.titleBar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background: var(--src) center center/cover no-repeat;
  position: relative;
  height: 96px;
  min-height: 96px;
  margin: 46px 0px 878px;
  justify-content: center;
}

@media (max-width: 767px) {
  .titleBar {
    height: 75px;
    min-height: 75px;
    margin: 155px 0px 0px;
  }
}

@media (max-width: 479px) {
  .titleBar {
    height: 56px;
    min-height: 56px;
    margin: 162px 0px 0px;
  }
}

.text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
}

.text_item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  -webkit-box-flex: 0;
      -ms-flex: 0 1 84px;
          flex: 0 1 84px;
  margin-left: 12px;
}

@media (max-width: 479px) {
  .text_item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 42px;
            flex: 0 0 42px;
  }
}

.logo {
  width: 100%;
  height: auto;
  vertical-align: top;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center top;
     object-position: center top;
}

@media (max-width: 767px) {
  .logo {
    height: 63px;
  }
}

@media (max-width: 479px) {
  .logo {
    height: auto;
  }
}

.text_spacer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  -webkit-box-flex: 0;
      -ms-flex: 0 1 20px;
          flex: 0 1 20px;
}

.text_item1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
}

.partyTitle {
  font: 700 24px/1.6 "Noto Sans TC", Helvetica, Arial, serif;
  color: white;
  letter-spacing: 0.72px;
  width: 100%;
  height: 100%;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .partyTitle {
    font-size: 16px;
    text-align: left;
  }
}
/*# sourceMappingURL=ClientLayout.module.css.map */
/* This source code is exported from pxCode, you can get more document from https://www.pxcode.io */
.block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: #fafafa;
}

.block_layout {
  position: relative;
  /* overflow: hidden; */
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media (max-width: 991px) {
  .flex {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    row-gap: 0px;
  }
}

.flex_layout {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  height: 100vh;
}

.flex_item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
}

@media (max-width: 767px) {
  .flex_item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
  }
}

.topCover {
  width: 100%;
  height: 100%;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.flex_spacer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
}

@media (max-width: 991px) {
  .flex_spacer {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 0px;
            flex: 0 0 0px;
  }
}

@media (max-width: 767px) {
  .flex_spacer {
    display: none;
  }
}

.flex_item1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 734px;
          flex: 1 1 734px;
}

@media (max-width: 991px) {
  .flex_item1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
  }
}

@media (max-width: 767px) {
  .flex_item1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
  }
}

.container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.container_layout {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  width: 75%;
  margin: 0 auto;
  justify-content: center;
}

@media (max-width: 767px) {
  .container_layout {
    flex-grow: 0;
  }
}
/*# sourceMappingURL=LoginPage.module.css.map */
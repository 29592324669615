/* This source code is exported from pxCode, you can get more document from https://www.pxcode.io */
.cardBox {
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-box-shadow: 0px 20px 34px 0px rgba(0, 0, 0, 0.0784314);
          box-shadow: 0px 20px 34px 0px rgba(0, 0, 0, 0.0784314);
  border-radius: 16px 16px 16px 16px;
  cursor: pointer;
}

.resultCard {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  background-color: none;
}

.resultCard_layout {
  position: relative;
}

.block16 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.block16_layout {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  margin: 20px;
}

.subtitle {
  font: 700 14px/1.6 "Noto Sans TC", Helvetica, Arial, serif;
  color: #4a4a4a;
  letter-spacing: 1px;
  opacity: 0.69;
}

.subtitle_layout {
  position: relative;
}

.title {
  font: 700 18px/1.6 "Noto Sans TC", Helvetica, Arial, serif;
  color: #4a4a4a;
  letter-spacing: 1px;
}

.title_layout {
  position: relative;
  margin: 5px 0px;
}
/*# sourceMappingURL=ResultCard.module.css.map */

.iconBox {
  position: relative;
  overflow: hidden;
  width: 38px;
  min-width: 38px;
  margin: 33px 20px 33px 0px;
}

.icon1 {
  width: 100%;
  height: auto;
  vertical-align: top;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center top;
     object-position: center top;
}

.icon1_layout {
  position: relative;
  height: 19px;
  width: 12px;
  min-width: 12px;
  margin: 9px 13px 10px;
}

.listBox {
  margin: 0 20px 20px;
  font: 500 14px/1.6 "Noto Sans TC", Helvetica, Arial, serif;
  color: #4a4a4a;
}

.listItemBox {
  display: flex;
  justify-content: space-between; 
  margin-bottom: 10px;
}

.leftCol {
  width: 70%;
}
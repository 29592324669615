/* This source code is exported from pxCode, you can get more document from https://www.pxcode.io */
.userInfo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  width: 100%;
  margin: 0px 0px;
}

.title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title_layout {
  position: relative;
}

.name {
  font: 700 30px/1 "Noto Sans TC", Helvetica, Arial, serif;
  color: #4a4a4a;
  letter-spacing: 0.6px;
}

@media (max-width: 767px) {
  .name {
    font-size: 20px;
    text-align: left;
  }
}

.name_layout {
  position: relative;
  flex: 1;
  margin: 9px 0px;
}

.title_spacer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 318px;
          flex: 1 1 318px;
}

.title_item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
}

.logoutButton {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: #4a4a4a;
  border-radius: 6px 6px 6px 6px;
  cursor: pointer;
  transition: .5s;
}

.logoutButton_layout {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  width: 106px;
  min-width: 106px;
  justify-content: center;
  height: 40px;
}

.logoutButton:hover {
  opacity: .8;
}

.text {
  font: 700 16px/1 "Noto Sans TC", Helvetica, Arial, serif;
  color: white;
  letter-spacing: 1px;
  width: 100%;
  height: 100%;
  text-align: center;
  margin-bottom: 0;
}

.subInfo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 16px;
}

.subInfo_layout {
  position: relative;
  margin: 18px 0px 0px;
}

.email_layout {
  position: relative;
}

.subInfoText {
  font: 700 16px/1 "Noto Sans TC", Helvetica, Arial, serif;
  color: #4a4a4a;
  letter-spacing: 0.6px;
  opacity: 0.69;
  position: relative;
}

.department {
  color: #CE2226;
}

.jobTitle {
  color: #13B54A;
}

@media (max-width: 767px) {
  .subInfoText {
    font-size: 12px;
    text-align: left;
  }
}

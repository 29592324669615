.button {
  border: 0;
  padding: 0;
  width: 100%;
  cursor: pointer;
  background-color: #4A4A4A;
  border-radius: 8px;
  transition: .3s;
}

.button.disabled {
  background-color: #b1b1b1;
  cursor: not-allowed;
}

.main {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  color: #FFF;
  padding: 20px;
  font-family: Noto Sans TC;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1px;
  text-align: left;
}

.icon {
  font-size: 12px;
}

.spin_container {
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* This source code is exported from pxCode, you can get more document from https://www.pxcode.io */
.resultCards_layout {
  position: relative;
}

@media (max-width: 991px) {
  .resultCards_layout {
    margin: 0px;
  }
}

.resultCards {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 24px;
  margin-bottom: 100px;
}

.resultCard_layout {
  position: relative;
  height: 104px;
  width: 100%;
  margin: 0px 0px;
}
/*# sourceMappingURL=ProfilePage_results.module.css.map */
/* This source code is exported from pxCode, you can get more document from https://www.pxcode.io */
.sessionCard {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-box-shadow: 0px 20px 34px 0px rgba(0, 0, 0, 0.0784314);
          box-shadow: 0px 20px 34px 0px rgba(0, 0, 0, 0.0784314);
  border-radius: 16px 16px 16px 16px;
  transition: transform .5s;
  position: relative;
  height: 104px;
  will-change: transform;
}

.clickable {
  cursor: pointer;
}

@media (hover: hover) and (pointer: fine) {
  .clickable:hover {
    transform: translateX(10px);
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
  }
}

.sessionCard_item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 546px;
          flex: 1 1 546px;
}

.text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.text_layout {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  margin: 20px 0px 20px 20px;
  justify-content: center;
}

.subtitle {
  font: 700 14px/1.6 "Noto Sans TC", Helvetica, Arial, serif;
  color: #4a4a4a;
  letter-spacing: 1px;
  opacity: 0.69;
}

.subtitle_layout {
  position: relative;
}

.title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font: 700 18px/1.6 "Noto Sans TC", Helvetica, Arial, serif;
  color: #4a4a4a;
  letter-spacing: 1px;
  word-break: normal;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.title_box_layout {
  position: relative;
  margin: 5px 0px;
}

.sessionCard_spacer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  -webkit-box-flex: 0;
      -ms-flex: 0 1 10px;
          flex: 0 1 10px;
}

.sessionCard_item1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  -webkit-box-flex: 0;
      -ms-flex: 0 1 30px;
          flex: 0 1 30px;
}

@media (max-width: 575px) {
  .sessionCard_item1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
  }
}

@media (max-width: 479px) {
  .sessionCard_item1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 1 75px;
            flex: 0 1 75px;
  }
}

.icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  font-size: 16px;
}

.icon_layout {
  position: relative;
  overflow: hidden;
  height: 38px;
  width: 38px;
  min-width: 38px;
  margin: 33px 20px 33px 0px;
}

.icon1 {
  width: 100%;
  height: auto;
  vertical-align: top;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center top;
     object-position: center top;
}

.icon1_layout {
  position: relative;
  height: 19px;
  width: 12px;
  min-width: 12px;
  margin: 9px 13px 10px;
}
/*# sourceMappingURL=SessionCard.module.css.map */
/* This source code is exported from pxCode, you can get more document from https://www.pxcode.io */
.loginForm {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 75%;
  /* height: 100%; */
  margin: 0 auto;
}

.loginForm_spacer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
  min-height: 45px;
}

@media (max-width: 479px) {
  .loginForm_spacer {
    min-height: 65px;
  }
}

@media (max-width: 383px) {
  .loginForm_spacer {
    min-height: 35px;
  }
}

.loginForm_item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
}

.title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.title_layout {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  margin-bottom: 30px;
}

.loginTitle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font: 700 30px/1.6 "Noto Sans TC", Helvetica, Arial, serif;
  color: #4a4a4a;
  text-align: center;
  letter-spacing: 1px;
}

@media (max-width: 767px) {
  .loginTitle {
    font-size: 20px;
    text-align: center;
  }
}

.loginTitle_layout {
  position: relative;
}

.desc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font: 20px/1.6 "Noto Sans TC", Helvetica, Arial, serif;
  color: #7f7f7f;
  text-align: center;
  letter-spacing: 1px;
}

@media (max-width: 767px) {
  .desc {
    font-size: 14px;
    text-align: center;
  }
}

.desc_layout {
  position: relative;
  margin: 8px 0px 0px;
}

@media (max-width: 383px) {
  .desc_layout {
    margin: 3px 0px 0px;
  }
}

.accountArea {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.accountArea_layout {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  margin: 19px 0px 0px;
}

@media (max-width: 383px) {
  .accountArea_layout {
    margin: 10px 0px 0px;
  }
}

.accountTitle {
  font: 700 18px/1.6 "Noto Sans TC", Helvetica, Arial, serif;
  color: #7f7f7f;
  letter-spacing: 1px;
}

@media (max-width: 767px) {
  .accountTitle {
    font-size: 14px;
    text-align: left;
  }
}

.accountTitle_layout {
  position: relative;
}

.accountInput {
  background-color: white;
  border-radius: 8px 8px 8px 8px !important;
}

.accountInput_layout {
  position: relative;
  height: 52px;
  margin: 5px 0px 0px;
}

.accountError {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  font: 14px/1.6 "Noto Sans TC", Helvetica, Arial, serif;
  color: #f83f3f;
  text-align: right;
  letter-spacing: 1px;
}

@media (max-width: 767px) {
  .accountError {
    font-size: 12px;
    text-align: right;
  }
}

.accountError_layout {
  position: relative;
  margin: 2px 0px 0px;
}

.passwordArea {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.passwordArea_layout {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  margin: 5px 0px 0px;
}

.passwirdTitle {
  font: 700 18px/1.6 "Noto Sans TC", Helvetica, Arial, serif;
  color: #7f7f7f;
  letter-spacing: 1px;
}

@media (max-width: 767px) {
  .passwirdTitle {
    font-size: 14px;
    text-align: left;
  }
}

.passwirdTitle_layout {
  position: relative;
}

.passwordInput {
  background-color: white;
  border-radius: 8px 8px 8px 8px !important;
}

.passwordInput_layout {
  position: relative;
  height: 52px;
  margin: 5px 0px 0px;
}

.loginButton {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: #4a4a4a;
  border-radius: 8px 8px 8px 8px;
  cursor: pointer;
}

.loginButton_layout {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  margin: 0px 0px 0px;
}

@media (max-width: 383px) {
  .loginButton_layout {
    margin: 15px 0px 0px;
  }
}

.text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.text_layout {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  width: 68px;
  min-width: 68px;
  margin: 20px auto;
  align-items: center;
}

.text_spacer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
}

.loginText {
  font: 700 20px/1 "Noto Sans TC", Helvetica, Arial, serif;
  color: white;
  letter-spacing: 0.5px;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .loginText {
    font-size: 14px;
    text-align: left;
  }
}

.loginText_layout {
  position: relative;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
}

.text_spacer1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  -webkit-box-flex: 0;
      -ms-flex: 0 1 8px;
          flex: 0 1 8px;
}

.text_item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 7px;
          flex: 1 1 7px;
}

.icon_layout {
  color: white;
  position: relative;
  height: 11px;
  width: 7px;
  min-width: 7px;
  margin: 4px 0px 5px;
}

.loginForm_spacer1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
  min-height: 50px;
}

@media (max-width: 767px) {
  .loginForm_spacer1 {
    min-height: 45px;
  }
}

@media (max-width: 479px) {
  .loginForm_spacer1 {
    min-height: 80px;
  }
}

@media (max-width: 383px) {
  .loginForm_spacer1 {
    min-height: 45px;
  }
}
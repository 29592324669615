.container {
  position: fixed;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  background-color: #F7F7F7;
  display: flex;
  flex-direction: column;
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transition: 0.3s ease-in-out;
  padding: 30px 20px 37px;
  justify-content: center;
  will-change: transform;
  gap: 26px;
}

@media (max-width: 767px) {
  .container {
    position: fixed;
    left: 0;
    width: 100%;
  }
}
.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  row-gap: 10px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  font-weight: 700;
  font-family: "Noto Sans TC", Helvetica, Arial, serif;
  margin-top: 10px;
}

.leftOutlinedIcon {
  font-size: 26px;
  align-self: flex-start;
}

.closeIcon {
  font-size: 26px;
  align-self: flex-end;
}

.redeem_container {
  width: 100%;
  padding: 10px 0 60px;
}

.btn_primary {
  width: 90%;
  margin: 0 auto;
  color: #FFF;
  background-color: #4A4A4A;
  text-align: center;
  border-radius: 8px;
  font-size: 14px;
  padding: 16px;
  margin-top: 16px;
  cursor: pointer;
  font-weight: 700;
}

.btm_secondary {
  width: 90%;
  color: #4A4A4A;
  background-color: #FFF;
  text-align: center;
  border-radius: 8px;
  font-size: 14px;
  padding: 16px;
  margin-top: 16px;
  cursor: pointer;
  border: 1px solid #4A4A4A;
  font-weight: 700;
}

.state_container {
  display: flex;
  justify-content: center;
  -moz-column-gap: 16px;
       column-gap: 16px;
  width: 100%;
  margin-bottom: 20px;
}

.state {
  background-color: #FAFAFA;
  box-shadow: 0px 20px 34px rgba(0, 0, 0, 0.08);
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
}

.state_title {
  font-size: 18px;
}

.state_number {
  font-size: 40px;
}

.state_points {
  font-size: 12px;
}

.points_detail_title_area {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.points_detail_title {
  font-family: Noto Sans TC;
  font-size: 18px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 1px;
  color: #4A4A4A;
}

.points_detail_updated_at {
  font-family: Noto Sans TC;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 1px;
  text-align: left;
  color: #7f7f7f;
}

.points_list {
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 20px 34px 0px rgba(0, 0, 0, 0.0784313725);
  padding: 10px 20px 20px 20px;
  border-radius: 16px;
  max-height: calc(100vh - 405px);
  overflow-y: auto;
}

.points_item {
  padding: 10px 4.5px;
  border-bottom: 0.5px solid #C4C4C4;
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: Noto Sans TC;
  letter-spacing: 1px;
}
.points_item .left {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 2px;
}
.points_item .left .title {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #4A4A4A;
}
.points_item .left .time {
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  color: #7f7f7f;
}
.points_item .right {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-basis: 87px;
}
@media (max-width: 374px) {
  .points_item .right {
    flex-basis: 60px;
  }
}
.points_item .right .icon {
  width: 25px;
}
.points_item .right .point {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #4A4A4A;
}

.flexGrow {
  flex-grow: 1;
}

.booth_drawer {
  font-family: Noto Sans TC;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
}
.booth_drawer .title {
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 1px;
  color: #4A4A4A;
}
.booth_drawer .input_container {
  display: flex;
  flex-direction: column;
}
.booth_drawer .input_container .label {
  font-family: Noto Sans TC;
  font-size: 18px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 1px;
  text-align: left;
  color: #808080;
  margin-bottom: 5px;
}
.booth_drawer .input_container .input {
  border-radius: 8px;
  height: 50px;
}
.booth_drawer .input_container .input.error {
  border-color: #F93F3F;
}
.booth_drawer .button {
  margin-top: 50px;
}

.error_message {
  font-family: Noto Sans TC;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 1px;
  text-align: right;
  color: #F93F3F;
}

.transfer_drawer {
  font-family: Noto Sans TC;
  display: flex;
  flex-direction: column;
  gap: 35px;
  height: 100%;
}
.transfer_drawer .title {
  font-size: 18px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 1px;
  color: #4A4A4A;
}
.transfer_drawer .input_container {
  display: flex;
  align-items: center;
  gap: 10px;
}
.transfer_drawer .input_container .icon {
  width: 45px;
}
.transfer_drawer .input_container .amount {
  font-size: 32px;
  font-weight: 700;
  border: 1px solid #D9D9D9;
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1607843137) inset;
  height: 60px;
  flex-grow: 1;
  padding: 2px 13px 0px;
  letter-spacing: 1px;
}
.transfer_drawer .number_keyboard {
  flex-grow: 2;
  display: grid;
  grid-template-columns: repeat(3, 80px);
  justify-content: space-between;
  grid-auto-rows: auto;
  row-gap: 24px;
}
@media (max-width: 767px) {
  .transfer_drawer .number_keyboard {
    grid-auto-rows: 40px;
  }
}
.transfer_drawer .number_keyboard .number_button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 1px;
  color: #000;
  border: none;
  background-color: transparent;
  transition: 0.5s;
}
.transfer_drawer .number_keyboard .number_button:active {
  opacity: 0.3;
}

.result_success {
  font-family: Noto Sans TC;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  height: 100%;
}
.result_success .greenCheck {
  display: block;
  margin: 0 auto;
}
.result_success .title {
  font-size: 32px;
  font-weight: 700;
  line-height: 51px;
  letter-spacing: 1px;
  color: #55be79;
}
.result_success .desc {
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 1px;
  text-align: center;
}
.result_success .bold {
  font-weight: 700;
}
.result_success .illus {
  width: 350px;
}

.result_fail {
  font-family: Noto Sans TC;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding-bottom: 50px;
}
.result_fail .illus {
  width: 375px;
}
.result_fail .text {
  font-family: Open Sans;
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  margin-bottom: 0;
}
.result_fail .button {
  margin-top: 20px;
  width: 100px;
}

.emptyContainer {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.usePointButton {
  position: fixed;
  bottom: 20px;
  width: calc(100% - 48px) !important;
  max-width: 500px !important;
}

.bottomSpace {
  width: 1px;
  height: 65px;
  flex-shrink: 0;
}

.confirmModal {
  font-family: Roboto, sans-serif;
  font-size: 20px;
  line-height: 32px;
  color: #000;
  padding: 10px 10px 0px;
}
.confirmModal .buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
.confirmModal .cancelButton {
  background-color: #fff;
  border: 1px solid #4a4a4a;
  color: #4a4a4a;
}
.confirmModal .black {
  color: #4a4a4a;
}/*# sourceMappingURL=PointsScreen.module.css.map */
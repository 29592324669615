.container {
  position: fixed;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  background-color: #FAFAFA;
  display: flex;
  flex-direction: column;
  padding: 10%;
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transition: .6s ease-in-out;
  box-shadow: 0px 20px 34px rgba(0, 0, 0, 0.08);
  justify-content: center;
  will-change: transform;
}

.content {
  margin-top: 60px;
  width: 90%;
}

.image {
  margin-top: 60px;
  width: 100%;
}

.container.active {
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  will-change: transform;
}

.closeIcon {
  position: fixed;
  right: 20px;
  top: 20px;
  font-size: 26px;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  height: fit-content;
}

.subText {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 28px;
}

.title {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 28px;
  text-align: center;
}

.name {
  font-size: 22px;
  font-weight: 700;
}

.nickname {
  font-size: 16px;
  font-weight: 700;
  color: #4A4A4AB2;
}

.qrcodeContainer {
  width: 242px;
  height: 242px;
  background-color: #F7F7F7;
  margin-bottom: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 767px) {
  .container {
    position: fixed;
    left: 0;
    width: 100%;
  }

  .subText {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .title {
    font-size: 22px;
    margin-bottom: 20px;
  }

  .qrcodeContainer {
    width: 242px;
    height: 242px;
    margin-bottom: 20px;
  }
}

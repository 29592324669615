.page {
  height: 100%;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: black;
}

.landscape_video {
  width: 100vw;
  height: 100%;
  background-color: black;
}

.portrait_video {
  width: 100vw;
  padding-top: 10px;
  margin: auto 0;
}

.landscape_video_body {
  width: 100vw;
  height: 100%;
}

.portrait_video_body {
  width: 100vw;
  height: 30vh;
}

.video {
  display: grid;
  grid-template-rows: 1fr fit-content 10px 1fr;
  background-size: cover;
  background-position: center;
}

.video_body {
  grid-area: 3;
}

.video_title {
  color: #FFF;
  padding: 0 1em;
  font-size: 1em;
  grid-area: 4;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.side {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  background-color: #fff;
  height: 100%;
}

.noSide {
  display: none;
}

.chatRoom {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.side_chat {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-direction: column;
  overflow-y: hidden;
}

.side_chat_list {
  height: 100%;
  flex-grow: 1;
  overflow-y: hidden;
}

.side_push_btn {
  border: none;
  background: none;
  background-color: #676767;
  color: #FFF;
  width: 100%;
  height: 35px;
  display: block;
  cursor: pointer;
  z-index: 3;
}

.session_push_btn {
  border: none;
  background: none;
  background-color: #fff;
  color: #4A4A4A;
  width: 100%;
  height: 40px;
  display: block;
  cursor: pointer;
  z-index: 3;
  border-radius: 20px 20px 0 0;
}

.side_push_box {
  padding: 0 1.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.side_push_box2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.side_push_text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 320px;
  text-align: center;
  margin-top: 10px;
}

.side_push_card_show {
  height: calc(var(--height) * 1px);
  padding: 0;
  transition: height 0.4s ease-out;
  text-align: center;
  display: grid;
  place-items: center;
  margin: auto 0;
  overflow: hidden;
}

.side_push_card_hide {
  height: 0;
  overflow-y: hidden;
  transition: height 0.4s ease-out;
  text-align: center;
  display: grid;
  place-items: center;
}

.slot_machine {
  height: 350px;
  display: grid;
  place-items: center;
}

@media (min-width: 768px) {
  .page {
    flex-direction: row;
  }
  .side {
    flex: none;
    width: 375px;
  }
  .video {
    flex: 1;
  }
}

/* =============Popover============ */

.popover_box {
  text-align: center;
  line-height: 2;
  font-weight: normal;
  font-size: 12px;
}

.popover_name {
  font-size: 14px;
}


/* =============Award============ */

.award {
  height: 100%;
  background-color: 'red';
  overflow-y: scroll;
  padding: 0 15px;
  background-color: #F1F3F7;
}

.award_title {
  background-color: #FAFAFA;
  padding: 8px;
}

.award_item {
  padding: 6px;
  border-bottom: 1px solid #ccc
}

.award_no_item {
  padding: 8px;
}

.award_reload {
  color: #7BA9F0;
  font-size: 24px;
  position: absolute;
  bottom: 15px;
  right: 15px;
}

.award_col {
  display: inline-block;
  width: 150px;
}

.award_row {
  padding: 6px;
  border-top: 1px solid #ccc;
  background-color: #F1F3F7;
}

/* Profile */
.profileInfo {
  display: flex;
}

.activityBox {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #fff;
}

.sessionActBox {
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: #fff;
  border-radius: 20px 20px 0 0;
  box-shadow: 0px -4px 14px rgba(0, 0, 0, 0.06);
  z-index: 90;
}

@media (max-width: 767px) {
  .activityBox {
    width: 100%;
  }

  .sessionActBox {
    width: 100%;
  }
}

.hintText {
  font-size: 16px;
  text-align: center;
  margin-top: 36px;
}

.fakeEnterButton {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: rgba(74, 74, 74, 0.7);;
  border-radius: 8px;
  width: 65%;
  height: 60px;
  cursor: pointer;
  font: 700 20px/1 "Noto Sans TC", Helvetica, Arial, serif;
  color: white;
  letter-spacing: 0.5px;
  display: flex;
  justify-content: center;
}

.qrcodeButtonContainer {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 100px;
  z-index: 100;
  transition: .5s;
}

@media (hover: hover) and (pointer: fine) {
  .qrcodeButtonContainer:hover {
    transform: scale(1.1);
  }
}

.qrcodeButton {
  width: 100%;
  height: auto;
  cursor: pointer;
  vertical-align: top;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center top;
     object-position: center top;
}

@media (max-width: 767px) {
  .qrcodeButtonContainer {
    display: none;
  }
}

.backdrop {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 230px;
  left: 0;
  display: none;
}

.backdrop.show {
  display: block;
}

@media (max-width: 479px) {
  .backdrop {
    top: 218px;
  }
}

.sessionForm {
  margin-top: 28px;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .sessionForm {
    margin-top: 0px;
    margin-bottom: 27px;
  }
}